import axios from 'axios';
import { JSEncrypt } from 'jsencrypt';
import qs from 'qs';
import CryptoJS from 'crypto-js';
import pinyin from 'js-pinyin';
import vm from '../main';
import { Notify } from 'vant';
pinyin.setOptions({
  checkPolyphone: false, //是否检查多音字
  charCase: 0,
});

const util = {};

util.appid = 'jxjybmfwxyhdk'; // appid
util.flag = true; // true 本地 false 线上，
// util.isTestFace = false; // true 走人脸 false 不走人脸，
util.gftSignUrl =
  'https://ganfutong.jiangxi.gov.cn/jpaas-jags-server/interface/createsign.do'; //赣服通获取sign的url
util.gftApiUrl =
  'https://ganfutong.jiangxi.gov.cn/jpaas-jags-server/interface/gateway.do'; //赣服通请求接口url
// read env from configs file
const env = window['$env'] ? window['$env'] : process.env.NODE_ENV;
util.webSocketHost =
  env === 'development'
    ? window.CONFIG.bdWebSocketHostUrl
    : window.CONFIG.xsWebSocketHostUrl;
// util.baseUrl = env === 'development' ? './' : './';
// util.baseUrl = env === 'development' ? 'http://10.1.44.67:8099' : './';
// util.baseUrl = env === 'development' ? 'http://10.1.44.220:9000' : './';
// util.baseUrl = env === 'development' ? 'http://www.jxjyglj.gov.cn' : './';
util.baseUrl =
  env === 'development' ? window.CONFIG.bdUrl : window.CONFIG.requestUrl;
// util.baseUrl =  'http://jyqqhj.devops.com'
// util.qzBaseUrl = 'https://gzmobile.devops.com/'
// util.qzBaseUrl = 'https://219.153.113.57:11014/'

export var requestMethod = (util.http = axios.create({
  baseURL: util.baseUrl,
  timeout: 40000,
  withCredentials: true,
}));

// http请求拦截
util.http.interceptors.request.use(
  function (config) {
    config.headers.Authorization = localStorage.token;
    /* console.log(config,'config');
    if (!config.headers.token && !config.url.includes("login")) {
      // 说明没有token，直接踢回去让登录
      vm.$router.push("/");
      return;
    } */
    return config;
  },
  function (error) {
    // 请求失败的处理
    return Promise.reject(error);
  }
);
// http响应拦截
util.http.interceptors.response.use(
  function (res) {
    if (res.data.code == '401') {
      Notify({
        type: 'warning',
        message: '登录信息已失效，请重新登录',
        onClose: () => {
          // 执行跳转到登录页的逻辑
          localStorage.clear();
          window.location.href = '/';
        },
      });
    }
    return res;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (vm.$router.name !== 'login') {
            Notify({
              type: 'warning',
              message: '登录信息已失效，请重新登录',
              onClose: () => {
                // 执行跳转到登录页的逻辑
                localStorage.clear();
                if (!util.flag) {
                  vm.$router.push('/');
                } else {
                  vm.$router.push('/login');
                }
              },
            });
          } else {
            return Promise.reject(error);
          }
          break;
        default:
          return Promise.reject(error);
      }
    }
  }
);

/**
 * 将请求参数转换为URLSearchParams格式
 * post、put等，axios默认使用json格式，如果需要使用form格式的，调用该函数进行转换
 * data: object
 */
util.formUrlencoded = function (data) {
  return qs.stringify(data);
};

// 数据加密方法
const rsaPubKey =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC0Jr1NzVUQMburkZT6Rkt0eaPmH8TN6E258l2tZMJgVCP/sL4oKjroKYmNPBkSSiLKFr9wwJqfesMeef6ChGRUXjG6DX0oxQRe0f5/UnyEm/NicJwz9xwkU34gbuo1VB/EA2QZ5dl1rj9iSsiqKLK6/QFlVuzslRdAXYZC79vprwIDAQAB';

const privateKey =
  'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBALQmvU3NVRAxu6uRlPpGS3R5o+YfxM3oTbnyXa1kwmBUI/+wvigqOugpiY08GRJKIsoWv3DAmp96wx55/oKEZFReMboNfSjFBF7R/n9SfISb82JwnDP3HCRTfiBu6jVUH8QDZBnl2XWuP2JKyKoosrr9AWVW7OyVF0BdhkLv2+mvAgMBAAECgYA3q3D8ReNvaa7wycAbxvUyPRSk3FRYvNyoBXllR6m5Usb9EITyrHofnBZhipzExhwSDw2BrkMZ3TnGhTRc6MSHuQAnkI8g1yB/UJRLX752M+m/MBEm/xNEK4D1z1yIueGv8zxjKVdNw8o8WGJV0V2FMKWLWW/r6PfcUTGERQNIQQJBANv9O2q0cEB7lujyuYvFVTKzG6d+msrzSqZ6yNWQSqVfGy5W2KE39Bg9Iklg0nWg0hWjO+Kn+wQ2BuidlpXn+ykCQQDRpBRx2w6y7yPR8ZThpHPK8HL0ZKarf7oW7ZViAXspL3hhnMXSeq9oTMctdm1riCTlAeJ6Cu5Rk/K0sjLRFbEXAkEAooCGEoVlYgypZjxeWWQUUlZB+FEqUP60xxNuJWvn6A7AClP2w+iMNBd8q02NSXzZk0g4JW7Gms65/bzjDqsFOQJAUFqz+3AbkjpXYIFYr6R5PbAsX8C0ocezbFZki8xFiOPJIKEWsjaURUYokiRyjGUCv0SZqm3GKz6qKNik4b+OwQJAfynVAyHawEWB7laoDiKMk4rtX55TQdtzqmIlfI63wMCmtAByOauQp6YiUzht6I73C/wBQ7o49ys0RTU0LyDQuQ==';

util.encryptPassword = function (str) {
  if (rsaPubKey) {
    // 对密码进行加密
    let encryptor = new JSEncrypt(); // 新建JSEncrypt对象
    encryptor.setPublicKey(rsaPubKey); // 设置公钥
    // let abc = encryptor.encrypt(str); // 加密
    // encryptor.setPrivateKey(privateKey); // 设置私钥
    return encryptor.encrypt(str);
  } else {
    return str;
  }
};
util.decryptPassword = function (str) {
  if (privateKey) {
    let encryptKey = new JSEncrypt(); // 新建JSEncrypt对象
    encryptKey.setPrivateKey(privateKey); // 设置私钥
    return encryptKey.decrypt(str);
  } else {
    return str;
  }
};
const gftKry = '1q2w3e4r';
util.encryptCol = function (str) {
  return CryptoJS.AES.encrypt(str, gftKry).toString();
};
util.decryptCol = function (str) {
  let bytes = CryptoJS.AES.decrypt(str, gftKry);
  return bytes.toString(CryptoJS.enc.Utf8);
};

//格式转换 data传入的数组   attr按照数组的哪个属性排序
util.formatData = function (data, attr) {
  let obj = {};
  let reg = /^[A-Za-z]$/;
  data.forEach((item) => {
    if (reg.test(pinyin.getCamelChars(item[attr]).charAt(0))) {
      //如果是字母开头
      if (obj[pinyin.getCamelChars(item[attr]).charAt(0).toUpperCase()]) {
        obj[pinyin.getCamelChars(item[attr]).charAt(0).toUpperCase()].push(
          item
        );
      } else {
        obj[pinyin.getCamelChars(item[attr]).charAt(0).toUpperCase()] = [item];
      }
    } else {
      //不是字母开头 包括数字特殊符号等开头的
      if (obj['#']) {
        obj['#'].push(item);
      } else {
        obj['#'] = [item];
      }
    }
  });
  let arr = [];
  for (let key in obj) {
    let o = {
      index: key,
      list: obj[key],
    };
    arr.push(o);
  }
  //数组排序
  arr.sort((a, b) => {
    let x = a['index'].toLowerCase(),
      y = b['index'].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
  //数组的列表按人名排序
  arr.forEach((item) => {
    item.list.sort((x, y) => {
      let a = x[attr],
        b = y[attr];
      // 数字排在字符串前面
      if (typeof a === 'number' && typeof b === 'string') {
        return -1;
      }

      if (typeof a === 'string' && typeof b === 'number') {
        return 1;
      }

      // 当存在非数字时
      if (isNaN(a) || isNaN(b)) {
        // 全汉字的排在非全汉字的后面
        if (isAllChinese(a) && !isAllChinese(b)) {
          return 1;
        }
        if (!isAllChinese(a) && isAllChinese(b)) {
          return -1;
        }
        // 存在非数字的数据时，都转为字符串进行比较
        a = a.toString();
        b = b.toString();
        let result = 0;
        // 依次比较两个字符串的各项字符
        for (
          let index = 0;
          index < (a.length - b.length ? b.length : a.length);
          index++
        ) {
          // 汉字排在非汉字的后面
          if (!isChinese(a[index]) && isChinese(b[index])) {
            result = -1;
          }

          if (isChinese(a[index]) && !isChinese(b[index])) {
            result = 1;
          }

          // 若两个汉字进行比较，则比较他们的拼音首字母
          if (isChinese(a[index]) && isChinese(b[index])) {
            // let pinyinA = pinyin.getCamelChars(a[index]).charAt(0).toString()
            // let pinyinB = pinyin.getCamelChars(b[index]).charAt(0).toString()
            // result = pinyinA.localeCompare(pinyinB, 'zh-Hans-CN', {
            //   sensitivity: 'accent'
            // })
            return a.localeCompare(b);
          }

          // 若已经比较出结果，则跳出循环，不再继续比较剩余字符
          if (result !== 0) {
            break;
          }
        }

        // 只要有一个无法转换为数字——转换为字符串进行比较——先按字符排序，然后按照数字排序
        // return result || a.toString().localeCompare(b.toString(), 'zh-Hans-CN', {
        //   sensitivity: 'accent'
        // })
        //数字开头的排在非数字开头的前边
        if (isNumber(a[0]) && !isNumber(b[0])) {
          return -1;
        }
        if (!isNumber(a[0]) && isNumber(b[0])) {
          return 1;
        }
      } else {
        // 都能转换为数字——转换为数字进行比较——从小到大排序
        return Number(a) - Number(b);
      }
    });
  });
  if (arr[0]['index'] === '#') {
    let objf = arr[0];
    arr.splice(0, 1);
    arr.push(objf);
  }
  // console.log(arr)
  let iArr = [];
  arr.forEach((item) => {
    iArr.push(item['index']);
  });
  return {
    userList: arr,
    indexList: iArr,
  };
};
// 判断字符串是否全是中文
function isAllChinese(str) {
  return /^[\u4E00-\u9FA5]+$/.test(str);
}

// 判断字符是否为中文
function isChinese(char) {
  return /^[\u4E00-\u9FA5]$/.test(char);
}

// 判断字符是否为数字
function isNumber(char) {
  return /^[0-9]$/.test(char);
}

util.decryptData = function (data) {
  const key = CryptoJS.enc.Utf8.parse('2b7e151628aed5yuabf7158809cf4f3c');
  const decryptedData = CryptoJS.AES.decrypt(data, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decryptedData.toString(CryptoJS.enc.Utf8);
};

export default util;
